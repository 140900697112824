import React, { FC } from 'react';
import styled from 'styled-components';
import { DynamicImage, Loader, Text } from 'components';
import { Product } from 'types/product';
import { smMobile, tablet } from 'styles/breakpoints';
import CardContainer from './CardContainer';
import Button from './Button';
import CancelButton from './CancelButton';
import ErrorMessageUpgrade from './ErrorMessageText';
import { number } from 'yup';

interface BundlePlanCardProps extends PlanSectionProps {
  product?: Product;
  disabled?: boolean;
  onUpgradeClick?: () => void;
  onCancelClick?: () => void;

  imgWidth?: string;
  imgMargin?: string;
  errorMessage?: string;
  name?: string;
  price?: number;
  oldPrice?: number;
  currency?: string;
}

export interface PlanSectionProps {
  imgUrl?: string;
  oldPriceText: string;
  oneTimeText?: string;
  cardLabel: string;
  cardInnerImage?: string;
  cardTopLabel?: string;
  yesButtonTitle: string;
  noButtonTitle: string;
  showOldPrice?: boolean;
  discountPercentText?: string;
  perMonthText?: string;
  cardBgColor?: string;
  savings: number;
  hideCardBorder?: boolean;
}

const MainContainer = styled.div`
  max-width: 21.4375rem;
  width: 100%;
  padding: 4.5rem 1rem 4rem;
  box-sizing: unset;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const Price = styled(Text)`
  color: #05a56f;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 2rem;
  font-weight: 800;
  line-height: 140%;
  margin-bottom: 1rem;
`;

const UpgradeBtnContainer = styled.div`
  width: 100%;
  padding-bottom: 0.75rem;
  padding-top: 0.5rem;
`;

const OldPrice = styled(Text)`
  color: #e53535;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.011rem;
  text-decoration: line-through;
`;

const OneTimeText = styled(Text)`
  color: ${({ theme }) => theme.colors.dark60};
  margin-bottom: 1.5rem;
  text-align: center;
`;

const DiscountPercentContainer = styled.div`
  border-radius: 0.375rem;
  padding: 0 1.37rem;
  height: 2rem;
  position: absolute;
  top: -1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00a676;
`;

const DiscountPercent = styled(Text)`
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.012rem;
  text-transform: uppercase;
`;

const Name = styled.p`
  color: #1c1c28;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
`;

const Divider = styled.div`
  width: 100%;
  background-color: #1c1c28;
  height: 1px;
  margin-bottom: 0.62rem;
`;

const UpsellPayments = styled(DynamicImage)`
  margin: 1.5rem auto 0;
  width: 100%;
  max-width: 17.25rem;
`;

const InnerCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.25rem;
  width: 100%;
`;

const CardInnerImg = styled(DynamicImage)`
  margin-top: 2.25rem;
  margin-bottom: 0.81344rem;
  max-width: 16.5rem;
  width: 100%;
`;

const CardContainerStyled = styled(CardContainer)`
  padding: 0 0.81344rem 0 0.81344rem;
  margin-bottom: 0.5rem;
  @media ${tablet} {
    padding: 0 0.75rem 0 0.75rem;
  }
`;

const ButtonStyled = styled(Button)`
  max-width: 23.25rem;
`;

const BundlePlanCard: FC<BundlePlanCardProps> = ({
  product,
  disabled,
  onUpgradeClick,
  onCancelClick,
  name,
  imgUrl,
  oneTimeText,
  yesButtonTitle,
  noButtonTitle,
  imgMargin = '0',
  imgWidth = '343px',
  cardLabel,
  cardTopLabel,
  cardInnerImage,
  oldPrice,
  discountPercentText,
  perMonthText,
  cardBgColor,
  hideCardBorder,
  errorMessage,
  savings,
  ...props
}) => {
  const renderDiscountPercent = (saving: number) => {
    if (!discountPercentText) return null;
    const regex = /\${val}/;
    const savings = saving;
    const replacedString = discountPercentText.replace(
      regex,
      savings.toString(),
    );
    return (
      <DiscountPercentContainer>
        <DiscountPercent color="light0">{replacedString}</DiscountPercent>
      </DiscountPercentContainer>
    );
  };

  return (
    <MainContainer {...props}>
      <CardContainerStyled bgColor={cardBgColor}>
        <InnerCardContainer>
          {renderDiscountPercent(savings)}
          {cardInnerImage ? <CardInnerImg src={cardInnerImage} alt="" /> : null}
          <Name>{product?.name}</Name>
          {oldPrice ? (
            <OldPrice type="bodyS">
              {product?.currency}
              {oldPrice}
            </OldPrice>
          ) : null}
          {product?.finalPrice && (
            <Price>
              {product?.currency}
              {product?.finalPrice}
            </Price>
          )}
          {cardLabel ? (
            <>
              <Divider />
              <OneTimeText type="body">{cardLabel}</OneTimeText>
            </>
          ) : null}
        </InnerCardContainer>
      </CardContainerStyled>
      <ErrorMessageUpgradeStyled errorMessage={errorMessage} />
      <UpgradeBtnContainer>
        {disabled ? (
          <Loader wrapperHeight={'1'} />
        ) : (
          <ButtonStyled
            disabled={disabled}
            onClick={onUpgradeClick}
            bgColor="primary"
          >
            {yesButtonTitle}
          </ButtonStyled>
        )}
      </UpgradeBtnContainer>
      <CancelButton
        disabled={disabled}
        onCancelClick={onCancelClick}
        noButtonTitle={noButtonTitle}
      />

      <UpsellPayments src="payments/only-payment-icons.png" alt="" />
      {oneTimeText ? (
        <>
          <Divider />
          <OneTimeText type="body" textAlign="center">
            {oneTimeText}
          </OneTimeText>
        </>
      ) : null}
    </MainContainer>
  );
};

export default BundlePlanCard;

const ErrorMessageUpgradeStyled = styled(ErrorMessageUpgrade)`
  padding: 0.5rem 0;
`;
